import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BusinessError } from '../error-handler/error-handler';

@Component({
  selector: 'app-error-dialog',
  template:
    '<app-popup [title]="title" [content]="message" label="{{buttonLabel}}"> </app-popup>',
})
export class ErrorDialogComponent {
  title = 'Ein unerwarteter Fehler ist aufgetreten';
  message =
    'Wir arbeiten bereits an der Fehlerbehebung. Bitte versuchen Sie es später erneut!';
  buttonLabel = 'Verstanden';

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
    const error = data.error;
    console.error(error);
    if (error instanceof BusinessError) {
      this.title = 'Das hat leider nicht funktioniert';
      this.message = error.message;
    }
    if ('buttonLabel' in data) {
      this.buttonLabel = data.buttonLabel;
    }

    if ('title' in data && data.title) {
      this.title = data.title;
    }
    /*
    else {
      this.message = error.message;
    }
    */
  }
}
